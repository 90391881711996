import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { AdminAlternativeDrivesSettingsService } from "../service";
import { N1ED } from "@edsdk/n1ed-react";
// import {Editor} from "tinymce";
import { PICTURE_URL } from "../../../helpers/api.routes";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
// @ts-ignore
export const CreateForm = ({
  setFileDe,
  setFileIt,
  setFileFr,
  setImageDe,
  setImageIt,
  setImageFr,
  state,
  setState,
  setSponsorLogo,
}: any) => {
  const { t } = useTranslation();
  const firstLoad = useRef(true);
  const [editorData, setEditorData] = useState<any>();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const fileHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    switch (language) {
      case "de":
        return setFileDe(e.target.files![0]);
      case "fr":
        return setFileFr(e.target.files![0]);
      case "it":
        return setFileIt(e.target.files![0]);
      default:
        return setFileDe(e.target.files![0]);
    }
  };
  const imageHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    switch (language) {
      case "de":
        return setImageDe(e.target.files![0]);
      case "fr":
        return setImageFr(e.target.files![0]);
      case "it":
        return setImageIt(e.target.files![0]);
      default:
        return setImageDe(e.target.files![0]);
    }
  };

  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (true) {
      AdminAlternativeDrivesSettingsService.getSingle()
        .then((response) => {
          const { data } = response;
          setState(data);
        })
        .catch((error) => ErrorToast(error));
    }
  }, []);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }
  const sponsorLogoHandler = (e: any) => {
    setSponsorLogo(e.target.files[0]);
  };
  const deleteHandler = (e: any, id: number) => {
    e.preventDefault();
    AdminAlternativeDrivesSettingsService.deleteFile(id as number)
      .then((response) => {
        if (response?.status === "success") {
          SuccessToast(t(`toasts.successfully_deleted`));
          setState(response?.data);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={6} className="mt-2">
            <label>{t("global.title")}</label>
            <input
              name="title"
              type="text"
              className="form-control"
              onChange={(e) => changeHandler(e as any)}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={12} className="my-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("global.text")}
              </label>
            </div>
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
              }}
            />
            {/* <N1ED
              id={`text`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={editorData ? editorData : state ? state?.text : ""}
              onEditorChange={changeEditorHandler}
            /> */}
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("global.textFooter")}
              </label>
            </div>
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.description ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "description");
              }}
            />
            {/* <N1ED
              id={`textFooter`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              initialValue={
                editorData ? editorData : state ? state?.textFooter : ""
              }
              onEditorChange={changeEditorHandler}
            /> */}
          </Col>
        </Row>
        <Row className="my-3">
          <div className="">
            <label htmlFor="imageFileInvert">Sponsor logo</label>
            <input
              onChange={sponsorLogoHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFileInvert"
            />
          </div>
          {state?.sponsorLogo && (
            <div className="col-md-6 py-3">
              <img
                className="rounded"
                alt="200x200"
                width={200}
                src={PICTURE_URL + state?.sponsorLogo.path}
                data-holder-rendered="true"
              />
            </div>
          )}
        </Row>
        <Row className="my-3">
          <Col md={6}>
            <div className="">
              <label htmlFor="documentFile">
                {t("download.document")} German
              </label>
              <input
                onChange={(e) => fileHandler(e, "de")}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.files?.DE?.file && (
              <div className="row">
                <div className="col-md-9">
                  <div className="d-flex align-items-center mt-3">
                    <i className="bi bi-file-earmark display-3"></i>
                    <a
                      target="_blank"
                      download
                      href={PICTURE_URL + state?.files?.DE?.file?.path}
                    >
                      {state?.files?.DE?.file?.name}
                    </a>
                  </div>
                </div>
                <div className="col-md-3 my-auto">
                  <button
                    className="btn btn-danger ms-3"
                    onClick={(e) =>
                      deleteHandler(e, state?.files?.DE?.file?.id)
                    }
                  >
                    {t("global.remove")}
                  </button>
                </div>
              </div>
            )}
          </Col>
          <Col md={6}>
            <div className="">
              <label htmlFor="imageFile">{t("download.image")} German</label>
              <input
                onChange={(e) => imageHandler(e, "de")}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>

            {state?.files?.DE?.image && (
              <div className="row">
                <div className="col-md-9 py-3">
                  <img
                    className="rounded"
                    alt="200x200"
                    width={200}
                    src={PICTURE_URL + state?.files?.DE?.image?.path}
                    data-holder-rendered="true"
                  />
                </div>

                <div className="col-md-3 my-auto">
                  <button
                    className="btn btn-danger ms-3"
                    onClick={(e) =>
                      deleteHandler(e, state?.files?.DE?.image?.id)
                    }
                  >
                    {t("global.remove")}
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={6}>
            <div className="">
              <label htmlFor="documentFile">
                {t("download.document")} French
              </label>
              <input
                onChange={(e) => fileHandler(e, "fr")}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.files?.FR?.file && (
              <div className="row">
                <div className="col-md-9">
                  <div className="d-flex align-items-center mt-3">
                    <i className="bi bi-file-earmark display-3"></i>
                    <a
                      target="_blank"
                      download
                      href={PICTURE_URL + state?.files?.FR?.file?.path}
                    >
                      {state?.files?.FR?.file?.name}
                    </a>
                  </div>
                </div>
                <div className="col-md-3 my-auto">
                  <button
                    className="btn btn-danger ms-3"
                    onClick={(e) =>
                      deleteHandler(e, state?.files?.FR?.file?.id)
                    }
                  >
                    {t("global.remove")}
                  </button>
                </div>
              </div>
            )}
          </Col>
          <Col md={6}>
            <div className="">
              <label htmlFor="imageFile">{t("download.image")} French</label>
              <input
                onChange={(e) => imageHandler(e, "fr")}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>

            {state?.files?.FR?.image && (
              <div className="row">
                <div className="col-md-9 py-3">
                  <img
                    className="rounded"
                    alt="200x200"
                    width={200}
                    src={PICTURE_URL + state?.files?.FR?.image?.path}
                    data-holder-rendered="true"
                  />
                </div>
                <div className="col-md-3 my-auto">
                  <button
                    className="btn btn-danger ms-3"
                    onClick={(e) =>
                      deleteHandler(e, state?.files?.FR?.image?.id)
                    }
                  >
                    {t("global.remove")}
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="my-3">
          <Col md={6}>
            <div className="">
              <label htmlFor="documentFile">
                {t("download.document")} Italian
              </label>
              <input
                onChange={(e) => fileHandler(e, "it")}
                className="form-control"
                type="file"
                id="documentFile"
                accept="application/*"
              />
            </div>
            {state?.files?.IT?.file && (
              <div className="row">
                <div className="col-md-9">
                  <div className="d-flex align-items-center mt-3">
                    <i className="bi bi-file-earmark display-3"></i>
                    <a
                      target="_blank"
                      download
                      href={PICTURE_URL + state?.files?.IT?.file?.path}
                    >
                      {state?.files?.IT?.file?.name}
                    </a>
                  </div>
                </div>
                <div className="col-md-3 my-auto">
                  <button
                    className="btn btn-danger ms-3"
                    onClick={(e) =>
                      deleteHandler(e, state?.files?.IT?.file?.id)
                    }
                  >
                    {t("global.remove")}
                  </button>
                </div>
              </div>
            )}
          </Col>
          <Col md={6}>
            <div className="">
              <label htmlFor="imageFile">{t("download.image")} Italian</label>
              <input
                onChange={(e) => imageHandler(e, "it")}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>

            {state?.files?.IT?.image && (
              <div className="row">
                <div className="col-md-9 py-3">
                  <img
                    className="rounded"
                    alt="200x200"
                    width={200}
                    src={PICTURE_URL + state?.files?.IT?.image?.path}
                    data-holder-rendered="true"
                  />
                </div>
                <div className="col-md-3 my-auto">
                  <button
                    className="btn btn-danger ms-3"
                    onClick={(e) =>
                      deleteHandler(e, state?.files?.IT?.image?.id)
                    }
                  >
                    {t("global.remove")}
                  </button>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <div className="col-12 mt-5 d-flex justify-content-end text-align-center">
          <div>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.save")}` : `${t("global.save")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
