import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Login } from "../authentication/login/Login";
import { AdminBrand } from "./brand";
import { AdminCategory } from "./category";
import { PageTemplate } from "../shared/layout/PageTemplate";
import PageNotFound from "../shared/components/PageNotFound";
import { AdminCategoryDownload } from "./downloadCategory";
import { AdminDownload } from "./download";
import { AdminEventCategory } from "./eventCategory";
import { AdminSubscription } from "./subscription";
import { AdminEvent } from "./event";
import { AdminEventSummaryCategory } from "./eventSummaryCategory";
import { AdminEventSummary } from "./eventSummary";
import { AdminMagazineCategory } from "./magazineCategory";
import { AdminMenu } from "./menu";
import { AdminMagazine } from "./magazine";
import { AdminNews } from "./news";

import { AdminUser } from "./user";
import { CreateDownload } from "./download/components/CreateDownload";
import { UpdateDownload } from "./download/components/UpdateDownload";
import { CreateEvent } from "./event/components/CreateEvent";
import { UpdateEvent } from "./event/components/UpdateEvent";
import { CreateEventSummary } from "./eventSummary/components/CreateEventSummary";
import { UpdateEventSummary } from "./eventSummary/components/UpdateEventSummary";
import { CreateMagazine } from "./magazine/components/CreateMagazine";
import { UpdateMagazine } from "./magazine/components/UpdateMagazine";
import { Profile } from "./profile";
import { useTranslation } from "react-i18next";
import { AdminSettings } from "./globalSettings";
import { AdminCompanyDetails } from "./companyDetails";
import { AdminAdmin } from "./admin";
import { CreateAdmin } from "./admin/components/CreateAdmin";
import { UpdateAdmin } from "./admin/components/UpdateAdmin";
import { AdminAlbum } from "./album";
import { CreateAlbum } from "./album/components/CreateAlbum";
import { UpdateAlbum } from "./album/components/UpdateAlbum";
import { CreateUser } from "./user/components/CreateUser";
import { UpdateUser } from "./user/components/UpdateUser";
import { AdminGalleryImage } from "./galleryImage";
import { CreatePhoto } from "./galleryImage/components/CreatePhoto";
import { Gallery } from "./galleryImage/components/Gallery";
import AdminBanner from "./banner";
import { CreateBanner } from "./banner/components/CreateBanner";
import { UpdateBanner } from "./banner/components/UpdateBanner";
import AdminNewsLetter from "./newsletter";

import AdminNewsTheme from "./newsTheme";
import { CreateNewsTheme } from "./newsTheme/components/CreateNewsTheme";
import { UpdateNewsTheme } from "./newsTheme/components/UpdateNewsTheme";
import AdminNewsletterEmailCreate from "./newsletter/adminNewsletterEmailCreate";

import { CreateNewsletterEmail } from "./newsletter/adminNewsletterEmailCreate/components/CreateNewsletterEmail";
import { UpdateNewsletterEmail } from "./newsletter/adminNewsletterEmailCreate/components/UpdateNewsletterEmail";
import { UpdateNews } from "./news/components/UpdateNews";
import { CreateNews } from "./news/components/CreateNews";
import AdminNewsletterTestEmails from "./newsletter/adminNewsletterTestEmail";
import { CreateTestEmail } from "./newsletter/adminNewsletterTestEmail/components/CreateNewsTheme";
import { UpdateTestEmail } from "./newsletter/adminNewsletterTestEmail/components/UpdateNewsTheme";
import { AdminSubmenu } from "./submenu";
import { Dashboard } from "./dashboard";
import MenuPosition from "./menuPosition";
import { CreateMenuPosition } from "./menuPosition/components/CreateMenuPosition";
import { UpdateMenuPosition } from "./menuPosition/components/UpdateMenuPosition";
import { CreateMenu } from "./menu/components/CreateMenu";
import { UpdateMenu } from "./menu/components/UpdateMenu";
import { AdminFeaturedArticles } from "./featuredArticles";
import { BannerZona } from "./banner/components/BannerZona";
import { AdminHomeSettings } from "./homeSettings";
import { AdminNewsletterSent } from "./newsletter/adminNewsletterSent";
import { StaticsNewsletterSent } from "./newsletter/adminNewsletterSent/components/StaticsNewsletterSent";
import { AdminMediadaten } from "./mediadaten";
import { AdminFleet } from "./fleet";
import { CreateFleet } from "./fleet/components/CreateFleet";
import { UpdateFleet } from "./fleet/components/UpdateFleet";
import { ForgotPassword } from "../authentication/login/ForgotPassword";
import { AdminPage } from "./page";
import { CreatePage } from "./page/components/CreatePage";
import { UpdatePage } from "./page/components/UpdatePage";
import { AdminGalleryVideo } from "./galleryVideo";
import { CreateVideo } from "./galleryVideo/components/CreateVideo";
import { UpdateVideo } from "./galleryVideo/components/UpdateVideo";
import { AdminEditorChoice } from "./editorChoice";
import { NewsletterSettings } from "./newsletter/adminNewsletterSettings";
import { EducationContinuing } from "./education/continuing";
import { UpdateFormContinuing } from "./education/continuing/components/UpdateForm";
import { CreateFormContinuing } from "./education/continuing/components/CreateFormContinuing";
import { AdminProduct } from "./product";
import { CreateProduct } from "./product/components/CreateProduct";
import { UpdateProduct } from "./product/components/UpdateProduct";
import { AdminProductCategory } from "./productCategory";
import { AdminAlternativeDrivesCategory } from "./alternativeDrivesCategory";
import { AdminAlternativeDrives } from "./alternativeDrives";
import { CreateAlternativeDrives } from "./alternativeDrives/components/CreateAlternativeDrives";
import { UpdateAlternativeDrives } from "./alternativeDrives/components/UpdateAlternativeDrives";
import { AlternativeDrivesSettings } from "./alternativeDrivesSettings";
import { CreateFormSettings } from "./alternativeDrivesSettings/components/CreateForm";
import { UpdateFormSettings } from "./alternativeDrivesSettings/components/UpdateForm";
import { ExportNewsletter } from "./newsletter/adminNewsletterExpert";
import { ExportUser } from "./user/components/UserExport";
import { UserActive } from "./user/components/UserActive";
import { UserInactive } from "./user/components/UserInactive";
import { UserPending } from "./user/components/UserPending";
import { ResetPassword } from "../authentication/login/ResetPassword";
import { ErrorList } from "./newsletter/adminNewsletterSent/components/ErrorList";
import { AdminWaitingNews } from "./waitingNews";
import { GreenPages } from "./greenPages";
import { AdminGreenPagesCategory } from "./greenPagesCategory";
import { CreateGreenPages } from "./greenPages/components/CreateGreenPages";
import { UpdateGreenPages } from "./greenPages/components/UpdateGreenPages";
import { AdminTests } from "./tests";
import { CreateTests } from "./tests/components/CreateTests";
import { UpdateTests } from "./tests/components/UpdateTests";
import { AdminModel } from "./model";
import { CreateModel } from "./model/components/CreateModel";
import { UpdateModel } from "./model/components/UpdateModel";
import { AdminModelCategory } from "./modelCategory";
import { ModelGallery } from "./modelGallery";
import { CreateGallery } from "./modelGallery/components/CreateGallery";
import { ModelGalleryUpdate } from "./modelGallery/components/ModelGalleryUpdate";
import { AdminMarket } from "./market";
import { CreateMarket } from "./market/components/CreateMarket";
import { UpdateMarket } from "./market/components/UpdatePage";
import { Team } from "./team";
import { CreateTeam } from "./team/components/CreateTeam";
import { UpdateTeam } from "./team/components/UpdateTeam";

export function BaseRoutes() {
  const { t } = useTranslation();
  const [pageTitle, setPageTitle] = useState<string>("/");
  const { pathname } = useLocation();
  useEffect(() => {
    switch (true) {
      // LOGIN
      case pathname === "/":
        return setPageTitle("login");

      case pathname === "/reset-password":
        return setPageTitle("reset");

      // ADMIN
      case pathname === "/admin":
        return setPageTitle(t("admin.admin"));

      case pathname === "/admin/admin/create":
        return setPageTitle(t("admin.create_admin"));

      case pathname.includes("/admin/admin/update/"):
        return setPageTitle(t("admin.update_admin"));

      // BANNER
      case pathname === "/admin/banner":
        return setPageTitle(t("banner.banner"));

      case pathname.includes("/admin/banner/create"):
        return setPageTitle(t("banner.create_banner"));

      case pathname.includes("/admin/banner/update"):
        return setPageTitle(t("banner.update_banner"));

      case pathname === "/admin/banner-zone":
        return setPageTitle(t("banner.banner_zone"));
      // MENU POSITION
      case pathname === "/admin/menu-position":
        return setPageTitle(t("menu_position.menu_position"));

      case pathname.includes("/admin/menu-position/create"):
        return setPageTitle(t("menu_position.create_menu_position"));

      case pathname.includes("/admin/menu-position/update"):
        return setPageTitle(t("menu_position.update_menu_position"));

      // CATEGORY
      case pathname === "/admin/category":
        return setPageTitle(t("table_headers.category"));

      // COMPANY
      case pathname === "/admin/company":
        return setPageTitle(t("settings.company_details"));

      // GREEN PAGES
      case pathname === "/admin/green-pages":
        return setPageTitle(t("green_pages.green_pages"));
      case pathname === "/admin/green-pages/create":
        return setPageTitle(t("green_pages.green_pages_create"));
      case pathname?.includes("/admin/green-pages/update"):
        return setPageTitle(t("green_pages.green_pages_update"));
      case pathname === "/admin/green-pages/category":
        return setPageTitle(t("green_pages.green_pages_cat"));
      case pathname === "/admin/provider-gallery":
        return setPageTitle(t("green_pages.provider_gallery"));
      case pathname === "/admin/provider-gallery/create":
        return setPageTitle(t("green_pages.provider_gallery_create"));
      case pathname?.includes("/admin/provider-gallery/update"):
        return setPageTitle(t("green_pages.provider_gallery_create"));

      //TEAM
      case pathname === "/admin/team":
        return setPageTitle(t("team.team"));
      case pathname === "/admin/team/create":
        return setPageTitle(t("team.team_create"));
      case pathname?.includes("/admin/team/update"):
        return setPageTitle(t("team.team_update"));

      // DOWNLOAD
      case pathname === "/admin/download":
        return setPageTitle(t("download.download"));

      case pathname.includes("/admin/download/create"):
        return setPageTitle(t("download.create_download"));

      case pathname.includes("/admin/download/update/"):
        return setPageTitle(t("download.update_download"));

      // PAGE
      case pathname === "/admin/page":
        return setPageTitle(t("page.page"));

      case pathname.includes("/admin/page/create"):
        return setPageTitle(t("page.create_page"));

      case pathname.includes("/admin/page/update/"):
        return setPageTitle(t("page.update_page"));

      // FLEET
      case pathname === "/admin/fleet":
        return setPageTitle(t("fleet.fleet"));

      case pathname.includes("/admin/fleet/create"):
        return setPageTitle(t("fleet.create_fleet"));

      case pathname.includes("/admin/fleet/update/"):
        return setPageTitle(t("fleet.update_fleet"));

      // DOWNLOAD CATEGORY
      case pathname === "/admin/download-category":
        return setPageTitle(t("download_category.download_category"));

      // EVENT SUMMARY CATEGORY
      case pathname.includes("/admin/event-summary/category"):
        return setPageTitle(t("event_sum_cat.event_sum_cat"));

      // EVENT SUMMARY
      case pathname.includes("/admin/event-summary"):
        return setPageTitle(t("admin_event_summary.event_summary"));

      case pathname.includes("/admin/event-summary/create"):
        return setPageTitle(t("admin_event_summary.create_event_summary"));

      case pathname.includes("/admin/event-summary/update/"):
        return setPageTitle(t("admin_event_summary.update_event_summary"));
      // EDUCATION
      case pathname.includes("/admin/education-continuing"):
        return setPageTitle(t("education.continuing"));
      case pathname === "/admin/education-continuing":
        return setPageTitle(t("education.continuing"));
      case pathname === "/admin/education-continuing/create":
        return setPageTitle(t("education.continuing"));
      // EVENT
      case pathname === "/admin/event":
        return setPageTitle(t("event.event"));

      case pathname.includes("/admin/event/create"):
        return setPageTitle(t("event.create_event"));

      case pathname.includes("/admin/event/update/"):
        return setPageTitle(t("event.update_event"));

      // EVENT CATEGORY
      case pathname === "/admin/event-category":
        return setPageTitle(t("event_cat.event_cat"));

      // alternative drives
      case pathname === "/admin/alternative-drives":
        return setPageTitle(t("alternative_drives.alternative_drives"));

      case pathname.includes("/admin/alternative-drives/create"):
        return setPageTitle(t("alternative_drives.create"));

      case pathname.includes("/admin/alternative-drives/update/"):
        return setPageTitle(t("alternative_drives.update"));

      // alternative drives CATEGORY
      case pathname === "/admin/alternative-drives-category":
        return setPageTitle(
          t("alternative_drives.alternative_drives_category")
        );
      // alternative drives settings
      case pathname.includes("/admin/alternative-drives-settings"):
        return setPageTitle(t("alternative_drives.settings"));

      // product
      case pathname === "/admin/product":
        return setPageTitle(t("product.product"));

      case pathname.includes("/admin/product/create"):
        return setPageTitle(t("product.create_product"));

      case pathname.includes("/admin/product/update/"):
        return setPageTitle(t("product.update_product"));

      // product CATEGORY
      case pathname === "/admin/product-category":
        return setPageTitle(t("product.product_category"));

      // GALLERY
      case pathname === "/admin/gallery":
        return setPageTitle(t("gallery.gallery"));
      case pathname === "/admin/video-gallery":
        return setPageTitle(t("video_gallery.video_gallery"));

      case pathname === "/admin/album":
        return setPageTitle(t("album.album"));

      case pathname.includes("/admin/album/update"):
        return setPageTitle(t("album.update_album"));

      case pathname.includes("/admin/video-update"):
        return setPageTitle(t("album.update_album"));

      case pathname.includes("/admin/album/create"):
        return setPageTitle(t("album.create_new_album"));

      case pathname === "/admin/gallery/create":
        return setPageTitle(t("gallery.add_new_photo"));

      case pathname.includes("/admin/gallery/"):
        return setPageTitle(t("gallery.gallery"));

      case pathname.includes("/admin/gallery/update/"):
        return setPageTitle(t("gallery.gallery"));

      // SUBSCRIPTION
      case pathname.includes("/admin/subscription"):
        return setPageTitle(t("subscription.subscription"));

      case pathname.includes("/admin/subscription/create"):
        return setPageTitle(t("subscription.subscription"));

      case pathname.includes("/admin/gallery/update/"):
        return setPageTitle(t("subscription.update_subscription"));

      // MAGAZINE
      case pathname === "/admin/magazine":
        return setPageTitle(t("magazine.magazine"));

      // MAGAZINE CATEGORY
      case pathname === "/admin/magazine-category":
        return setPageTitle(t("magazine_cat.magazine_cat"));

      case pathname.includes("/admin/magazine/create"):
        return setPageTitle(t("magazine.create_magazine"));

      case pathname.includes("/admin/magazine/update/"):
        return setPageTitle(t("magazine.update_magazine"));
      // MARKET
      case pathname === "/admin/market":
        return setPageTitle(t("market.market"));

      case pathname.includes("/admin/market/create"):
        return setPageTitle(t("market.create_market"));

      case pathname.includes("/admin/market/update/"):
        return setPageTitle(t("market.update_market"));

      // MENU
      case pathname === "/admin/menu":
        return setPageTitle(t("menu.menu"));
      case pathname.includes("/admin/menu/create"):
        return setPageTitle(t("menu.create_new_menu"));
      case pathname.includes("/admin/menu/update/"):
        return setPageTitle(t("menu.update_menu"));

      case pathname.includes("/admin/menu/submenu/"):
        return setPageTitle(t("submenu.submenu"));

      // NEWS
      case pathname === "/admin/news":
        return setPageTitle(t("news.news"));

      case pathname === "/admin/featured-articles":
        return setPageTitle(t("news.featured_articles"));

      case pathname.includes("/admin/news/create"):
        return setPageTitle(t("news.create_news"));

      case pathname.includes("/admin/news/update/"):
        return setPageTitle(t("news.update_news"));

      // TESTS
      case pathname === "/admin/tests":
        return setPageTitle(t("test.tests"));

      case pathname.includes("/admin/tests/create"):
        return setPageTitle(t("test.create_tests"));

      case pathname.includes("/admin/tests/update/"):
        return setPageTitle(t("test.update_tests"));

      case pathname.includes("/admin/brand"):
        return setPageTitle(t("brand.brand"));

      case pathname === "/admin/editor-choice":
        return setPageTitle(t("editor_choice.editor_choice"));

      case pathname === "/admin/waiting-news":
        return setPageTitle(t("global.waiting_news"));

      // MODEL
      case pathname === "/admin/model":
        return setPageTitle(t("model.model"));
      case pathname === "/admin/model/create":
        return setPageTitle(t("model.create_model"));
      case pathname.includes("/admin/model/update"):
        return setPageTitle(t("model.update_model"));
      case pathname === "/admin/model-category":
        return setPageTitle(t("model.model_cat"));
      case pathname === "/admin/model-gallery":
        return setPageTitle(t("model.model_gallery"));
      case pathname === "/admin/model-gallery/create":
        return setPageTitle(t("model.model_gallery_create"));
      case pathname?.includes("/admin/model-gallery/update"):
        return setPageTitle(t("model.model_gallery_create"));

      // NEWS THEME
      case pathname === "/admin/news-theme":
        return setPageTitle(t("news_theme.news_theme"));

      case pathname.includes("/admin/news-theme/create"):
        return setPageTitle(t("news_theme.news_theme"));

      case pathname.includes("/admin/news-theme/update/"):
        return setPageTitle(t("news_theme.update_news_theme"));

      // NEWSLETTER
      case pathname === "/admin/newsletter":
        return setPageTitle(t("newsletter.newsletter"));

      case pathname.includes("/admin/newsletter/create"):
        return setPageTitle(t("newsletter.newsletter"));

      case pathname.includes("/admin/newsletter/update/"):
        return setPageTitle(t("newsletter.update_newsletter"));

      case pathname === "/admin/newsletters/list":
        return setPageTitle(t("newsletter.sent_newsletter"));

      case pathname === "/admin/newsletter-settings":
        return setPageTitle(t("newsletter.settings"));

      case pathname.includes("/admin/newsletters/list/"):
        return setPageTitle(t("newsletter.statics_of_sent_newsletter"));
      case pathname.includes("/admin/newsletters/errors/list"):
        return setPageTitle(t("newsletter.list_of_errors"));
      // NEWSLETTER EMAIL
      case pathname.includes("/admin/newsletter-email"):
        return setPageTitle(t("newsletter_email.newsletter_email"));

      case pathname.includes("/admin/newsletter-email/registered"):
        return setPageTitle(t("newsletter_email.newsletter_email_registered"));

      case pathname.includes("/admin/newsletter-email/deregistered"):
        return setPageTitle(
          t("newsletter_email.newsletter_email_deregistered")
        );

      case pathname.includes("/admin/newsletter-email/create"):
        return setPageTitle(t("newsletter.create_newsletter_email"));

      case pathname.includes("/admin/newsletter-email/update/"):
        return setPageTitle(t("newsletter.update_newsletter_email"));
      case pathname.includes("/admin/newsletter-export"):
        return setPageTitle(t("newsletter.export"));
      // NEWSLETTER TEST EMAIL
      case pathname.includes("/admin/newsletter-email-test"):
        return setPageTitle(t("newsletter_email.newsletter_email_test"));

      case pathname.includes("/admin/newsletter-email-test/create"):
        return setPageTitle(t("newsletter.create_newsletter_email_test"));

      case pathname.includes("/admin/newsletter-email-test/update/"):
        return setPageTitle(t("newsletter.update_newsletter_email_test"));

      // USER
      case pathname === "/admin/user":
        return setPageTitle(t("user.user"));
      case pathname === "/admin/user/active":
        return setPageTitle(t("user.active_user"));
      case pathname === "/admin/user/pending":
        return setPageTitle(t("user.pending_user"));
      case pathname === "/admin/user/inactive":
        return setPageTitle(t("user.inactive_user"));

      case pathname.includes("/admin/user/create"):
        return setPageTitle(t("user.create_new_user"));

      case pathname.includes("/admin/user/update/"):
        return setPageTitle(t("user.update_user"));

      case pathname.includes("/admin/user/export"):
        return setPageTitle(t("user.export"));

      // APP HOME
      case pathname === "/app":
        return setPageTitle(t("side_bar.home"));

      // APP PROFILE
      case pathname.includes("app/profile"):
        return setPageTitle(t("profile.profile"));

      // SETTINGS
      case pathname === "/admin/settings":
        return setPageTitle(t("settings.settings"));

      // HOME SETTINGS
      case pathname === "/admin/home-settings":
        return setPageTitle(t("settings.home_settings"));
      case pathname === "/admin/home-settings-second":
        return setPageTitle(t("settings.home_settings_second"));
      // MEDIADATEN
      case pathname === "/admin/mediadaten":
        return setPageTitle(t("mediadaten.mediadaten"));

      // INVALID CASE
      default:
        setPageTitle("invalid");
    }
  }, [pathname]);

  const hideSidebar = ["/", "login", "reset", "invalid", "invalid"];
  return (
    <>
      {hideSidebar.includes(pageTitle) ? (
        // <ErrorBoundary key={pathname}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      ) : (
        // </ErrorBoundary>
        <>
          <PageTemplate title={pageTitle}>
            <Routes>
              {/* ADMIN */}

              <Route path="/admin" element={<AdminAdmin />} />
              <Route path="/admin/admin/create" element={<CreateAdmin />} />
              <Route path="/admin/admin/update/:id" element={<UpdateAdmin />} />

              {/* ALBUM */}

              <Route path="/admin/album" element={<AdminAlbum />} />
              <Route path="/admin/album/create" element={<CreateAlbum />} />
              <Route path="/admin/album/update/:id" element={<UpdateAlbum />} />

              {/* BANNER */}

              <Route path="/admin/banner" element={<AdminBanner />} />
              <Route path="/admin/banner-zone" element={<BannerZona />} />
              <Route path="/admin/banner/create" element={<CreateBanner />} />
              <Route
                path="/admin/banner/update/:id"
                element={<UpdateBanner />}
              />
              {/* MENU POSITION */}

              <Route path="/admin/menu-position" element={<MenuPosition />} />
              <Route
                path="/admin/menu-position/create"
                element={<CreateMenuPosition />}
              />
              <Route
                path="/admin/menu-position/update/:id"
                element={<UpdateMenuPosition />}
              />

              {/* COMPANY */}

              <Route path="/admin/company" element={<AdminCompanyDetails />} />

              {/* DOWNLOAD */}

              <Route path="/admin/download" element={<AdminDownload />} />
              <Route
                path="/admin/download/create"
                element={<CreateDownload />}
              />
              <Route
                path="/admin/download/update/:id"
                element={<UpdateDownload />}
              />

              <Route
                path="/admin/download-category"
                element={<AdminCategoryDownload />}
              />
              {/* PAGE */}

              <Route path="/admin/page" element={<AdminPage />} />

              <Route path="/admin/page/create" element={<CreatePage />} />

              <Route path="/admin/page/update/:id" element={<UpdatePage />} />
              {/* MARKET */}

              <Route path="/admin/market" element={<AdminMarket />} />

              <Route path="/admin/market/create" element={<CreateMarket />} />

              <Route
                path="/admin/market/update/:id"
                element={<UpdateMarket />}
              />
              {/* FLEET */}

              <Route path="/admin/fleet" element={<AdminFleet />} />

              <Route path="/admin/fleet/create" element={<CreateFleet />} />

              <Route path="/admin/fleet/update/:id" element={<UpdateFleet />} />

              {/* <Route
                path="/admin/download-category"
                element={<AdminCategoryDownload />}
              /> */}

              {/* alternative-drives */}

              <Route
                path="/admin/alternative-drives"
                element={<AdminAlternativeDrives />}
              />
              <Route
                path="/admin/alternative-drives/create"
                element={<CreateAlternativeDrives />}
              />
              <Route
                path="/admin/alternative-drives/update/:id"
                element={<UpdateAlternativeDrives />}
              />
              <Route
                path="/admin/alternative-drives-category"
                element={<AdminAlternativeDrivesCategory />}
              />
              {/* <Route
                path="/admin/alternative-drives-settings"
                element={<AlternativeDrivesSettings />}
              /> */}
              <Route
                path="/admin/alternative-drives-settings"
                element={<CreateFormSettings />}
              />
              <Route
                path="/admin/alternative-drives-settings/create"
                element={<CreateFormSettings />}
              />
              <Route
                path="/admin/alternative-drives-settings/:id"
                element={<UpdateFormSettings />}
              />
              {/* product */}

              <Route path="/admin/product" element={<AdminProduct />} />
              <Route path="/admin/product/create" element={<CreateProduct />} />
              <Route
                path="/admin/product/update/:id"
                element={<UpdateProduct />}
              />
              <Route
                path="/admin/product-category"
                element={<AdminProductCategory />}
              />

              {/* EVENT */}

              <Route path="/admin/event" element={<AdminEvent />} />
              <Route path="/admin/event/create" element={<CreateEvent />} />
              <Route path="/admin/event/update/:id" element={<UpdateEvent />} />

              {/* EVENT-CATEGORY */}

              <Route
                path="/admin/event-category"
                element={<AdminEventCategory />}
              />
              <Route
                path="/admin/event-summary"
                element={<AdminEventSummary />}
              />
              <Route
                path="/admin/event-summary/category"
                element={<AdminEventSummaryCategory />}
              />
              <Route
                path="/admin/event-summary/create"
                element={<CreateEventSummary />}
              />
              <Route
                path="/admin/event-summary/update/:id"
                element={<UpdateEventSummary />}
              />
              {/* EDUCATION */}
              <Route
                path="/admin/education-continuing"
                element={<EducationContinuing />}
              />
              <Route
                path="/admin/education-continuing/create"
                element={<CreateFormContinuing />}
              />
              <Route
                path="/admin/education-continuing/:id"
                element={<UpdateFormContinuing />}
              />
              {/* GALLERY */}

              <Route path="/admin/gallery" element={<AdminGalleryImage />} />
              <Route
                path="/admin/video-gallery"
                element={<AdminGalleryVideo />}
              />
              <Route path="/admin/video-update/:id" element={<UpdateVideo />} />

              <Route path="/admin/gallery/create" element={<CreatePhoto />} />
              <Route
                path="/admin/gallery/video-create"
                element={<CreateVideo />}
              />
              <Route path="/admin/gallery/:id" element={<Gallery />} />

              {/* MAGAZINE */}

              <Route path="/admin/magazine" element={<AdminMagazine />} />
              <Route
                path="/admin/magazine-category"
                element={<AdminMagazineCategory />}
              />
              <Route
                path="/admin/magazine/create"
                element={<CreateMagazine />}
              />
              <Route
                path="/admin/magazine/update/:id"
                element={<UpdateMagazine />}
              />

              {/* MENU */}

              <Route path="/admin/menu" element={<AdminMenu />} />
              <Route path="/admin/menu/create" element={<CreateMenu />} />
              <Route path="/admin/menu/update/:id" element={<UpdateMenu />} />
              <Route
                path="/admin/menu/submenu/:id"
                element={<AdminSubmenu />}
              />

              {/* NEWS */}

              <Route path="/admin/category" element={<AdminCategory />} />
              <Route
                path="/admin/featured-articles"
                element={<AdminFeaturedArticles />}
              />
              <Route path="/admin/brand" element={<AdminBrand />} />
              <Route
                path="/admin/editor-choice"
                element={<AdminEditorChoice />}
              />
              <Route
                path="/admin/waiting-news"
                element={<AdminWaitingNews />}
              />
              <Route path="/admin/news" element={<AdminNews />} />
              <Route path="/admin/news/create" element={<CreateNews />} />
              <Route path="/admin/news/update/:id" element={<UpdateNews />} />

              <Route path="/admin/tests" element={<AdminTests />} />
              <Route path="/admin/tests/create" element={<CreateTests />} />
              <Route path="/admin/tests/update/:id" element={<UpdateTests />} />

              {/* NEWS-THEME */}
              <Route path="/admin/news-theme" element={<AdminNewsTheme />} />
              <Route
                path="/admin/news-theme/create"
                element={<CreateNewsTheme />}
              />
              <Route
                path="/admin/news-theme/update/:id"
                element={<UpdateNewsTheme />}
              />

              {/* NEWSLETTER */}
              <Route path="/admin/newsletter" element={<AdminNewsLetter />} />
              <Route
                path="/admin/newsletters/list"
                element={<AdminNewsletterSent />}
              />
              <Route
                path="/admin/newsletter-export"
                element={<ExportNewsletter />}
              />
              <Route
                path="/admin/newsletters/list/:id"
                element={<StaticsNewsletterSent />}
              />
              <Route
                path="/admin/newsletters/errors/list/:id"
                element={<ErrorList />}
              />

              {/* NEWSLETTER-EMAIL */}
              <Route
                path="/admin/newsletter-email"
                element={<AdminNewsletterEmailCreate />}
              />
              <Route
                path="/admin/newsletter-email/registered"
                element={<AdminNewsletterEmailCreate />}
              />
              <Route
                path="/admin/newsletter-email/deregistered"
                element={<AdminNewsletterEmailCreate />}
              />
              <Route
                path="/admin/newsletter-email/create"
                element={<CreateNewsletterEmail />}
              />
              <Route
                path="/admin/newsletter-email/update/:id"
                element={<UpdateNewsletterEmail />}
              />

              {/* NEWSLETTER-EMAIL-TEST */}
              <Route
                path="/admin/newsletter-email-test"
                element={<AdminNewsletterTestEmails />}
              />
              <Route
                path="/admin/newsletter-email-test/create"
                element={<CreateTestEmail />}
              />
              <Route
                path="/admin/newsletter-settings"
                element={<NewsletterSettings />}
              />
              <Route
                path="/admin/newsletter-email-test/update/:id"
                element={<UpdateTestEmail />}
              />

              {/* PROFILE */}
              <Route path="/app" element={<Dashboard />} />
              <Route path="/app/profile" element={<Profile />} />

              {/* SETTINGS */}
              <Route path="/admin/settings" element={<AdminSettings />} />

              {/* HOME SETTINGS */}
              <Route
                path="/admin/home-settings"
                element={<AdminHomeSettings />}
              />
              {/* SUBSCRIPTION */}
              <Route
                path="/admin/subscription"
                element={<AdminSubscription />}
              />

              {/* MEDIADATEN */}
              <Route path="/admin/mediadaten" element={<AdminMediadaten />} />

              {/* USER */}
              <Route path="/admin/user" element={<AdminUser />} />
              <Route path="/admin/user/active" element={<UserActive />} />
              <Route path="/admin/user/pending" element={<UserPending />} />
              <Route path="/admin/user/inactive" element={<UserInactive />} />
              <Route path="/admin/user/create" element={<CreateUser />} />
              <Route path="/admin/user/update/:id" element={<UpdateUser />} />
              <Route path="/admin/user/export" element={<ExportUser />} />

              {/* GREEN PAGES */}
              <Route path="/admin/green-pages" element={<GreenPages />} />
              <Route
                path="/admin/green-pages/category"
                element={<AdminGreenPagesCategory />}
              />
              <Route
                path="/admin/green-pages/create"
                element={<CreateGreenPages />}
              />
              <Route
                path="/admin/green-pages/update/:id"
                element={<UpdateGreenPages />}
              />

              {/* TEAM */}
              <Route path="/admin/team" element={<Team />} />
              <Route path="/admin/team/create" element={<CreateTeam />} />
              <Route path="/admin/team/update/:id" element={<UpdateTeam />} />

              {/* MODEL */}
              <Route path="/admin/model" element={<AdminModel />} />
              <Route path="/admin/model/create" element={<CreateModel />} />
              <Route path="/admin/model/update/:id" element={<UpdateModel />} />
              <Route
                path="/admin/model-category"
                element={<AdminModelCategory />}
              />
              <Route path="/admin/model-gallery" element={<ModelGallery />} />
              <Route
                path="/admin/model-gallery/create"
                element={<CreateGallery />}
              />
              <Route
                path="/admin/model-gallery/update/:id"
                element={<ModelGalleryUpdate />}
              />
            </Routes>
          </PageTemplate>
        </>
      )}
    </>
  );
}
