import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminMediadatenService } from "./service";
import { CreateForm } from "./components/CreateForm";
import { useTranslation } from "react-i18next";

export const AdminMediadaten = () => {
  const { t } = useTranslation();
  const [document, setDocument] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [data, setData] = useState();

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(data));
    document && formData.append("document", document);

    AdminMediadatenService.create(formData)
      .then((response) => {
        SuccessToast(t(`settings.successfully_saved`));

        setTriggerUpdate(!triggerUpdate);
      })
      .catch((error) => ErrorToast(error));
  };

  useEffect(() => {
    AdminMediadatenService.getAll()
      .then((response) => {
        const { data } = response;

        setData(data);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);

  return (
    <>
      <div className="col-12">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
          }}
        >
          <CreateForm
            state={data}
            setState={setData}
            document={document}
            setDocument={setDocument}
          />
        </Form>
      </div>
    </>
  );
};
