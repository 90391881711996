export const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      // "|",
      // "glossary-scan",
      // "glossary-ignore",
      // "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};
