import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { N1ED } from "@edsdk/n1ed-react";
// import { Editor } from "tinymce";
import { NewsletterSettingsService } from "./service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import "../../../assets/scss/EditorStyle.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editorConfigurationCustom } from "../../../shared/functions/editorConfigurationCustom";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
interface IState {
  textFooter: string;
  textMan: string;
  textWoman: string;
  textOther: string;
  senderEmail: string;
  image?: any;
}
export const NewsletterSettings = () => {
  const [state, setState] = useState<IState>({
    textFooter: "",
    textMan: "",
    textWoman: "",
    textOther: "",
    senderEmail: "",
  });
  const { t } = useTranslation();
  const [count, setCount] = useState({});
  const [editorData, setEditorData] = useState<any>();
  const firstLoad = useRef(true);
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const [image, setImage] = useState<File>();
  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  // const changeEditorHandler = (content: string, editor: Editor) => {
  //   setState((prev: any) => ({ ...prev, [editor?.id]: content }));
  //   if (!firstLoad.current) {
  //     setEditorData(editor);
  //   }
  //   firstLoad.current = false;
  // };
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
    setState({ ...state, image: e.target.files[0] });
  };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
  };
  const submitHandler = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("body", JSON.stringify(state));
    image && formData.append("image", image);
    NewsletterSettingsService.create(formData)
      .then((response) => {
        SuccessToast(t(`toasts.successfully_created`));
      })
      .catch((error) => ErrorToast(error));
  };
  const deleteImage = (e: any) => {
    e.preventDefault();
    NewsletterSettingsService.deleteImage()
      .then((response) => {
        setState((prev: any) => ({
          ...prev,
          image: response?.data?.image,
        }));
        SuccessToast(t("global.successfully_deleted"));
      })
      .catch((err) => {
        ErrorToast(err);
      });
  };
  useEffect(() => {
    NewsletterSettingsService.getAll()
      .then((response) => {
        const { data } = response;
        setState(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  return (
    <div className="card">
      <div className="card-body">
        <Row>
          <Col md={12} className="mb-3" id="custom-editor">
            <div>
              <label htmlFor="title" className="required-field">
                {t("newsletter.mr")}
              </label>
            </div>
            {/* <N1ED
              id={`textMan`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              value={state?.textMan ?? ""}
              // initialValue={
              //   editorData ? editorData : state ? state?.textMan : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */}
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.textMan ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "textMan");
              }}
            />
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <label htmlFor="title" className="required-field">
              {t("newsletter.woman")}
            </label>
            {/* <N1ED
              id={`textWoman`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              value={state?.textWoman ?? ""}
              // initialValue={
              //   editorData ? editorData : state ? state?.textWoman : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */}
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.textWoman ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "textWoman");
              }}
            />
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <label htmlFor="title" className="required-field">
              {t("newsletter.other")}
            </label>
            {/* <N1ED
              id={`textOther`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              value={state?.textOther ?? ""}
              // initialValue={
              //   editorData ? editorData : state ? state?.textOther : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */}
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.textOther ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "textOther");
              }}
            />
          </Col>
          <Col md={12} className="mb-3" id="custom-editor">
            <label htmlFor="title" className="required-field">
              {t("newsletter.footer")}
            </label>
            {/* <N1ED
              id={`textFooter`}
              onFocus={(e) => setSelectEditor(true)}
              apiKey={process.env.REACT_APP_EDITOR_KEY}
              value={state?.textFooter ?? ""}
              // initialValue={
              //   editorData ? editorData : state ? state?.textFooter : ""
              // }
              onEditorChange={changeEditorHandler}
            /> */}
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.textFooter ?? ""}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "textFooter");
              }}
            />
          </Col>
          <div className="mt-3">
            <label htmlFor="imageFile"> {t("download.image")}</label>
            <input
              onChange={fileHandler}
              className="form-control"
              type="file"
              accept="image/*"
              id="imageFile"
            />
          </div>
          {state?.image && (
            <div className="d-flex align-items-center mt-3  ">
              <i className="bi bi-file-earmark display-3"></i>
              {image?.name ?? state.image.name}
            </div>
          )}
        </Row>
        <Row>
          <Col md={12} className="mb-3">
            <label htmlFor="senderEmail" className="required-field">
              {t("global.sender_email")}
            </label>
            <input
              type="text"
              name="senderEmail"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.senderEmail ?? ""}
            />
          </Col>
        </Row>
        <Row>
          <div className="col-12 mt-4 d-flex justify-content-end text-align-center">
            <div>
              <button
                className="btn btn-danger me-2"
                onClick={(e) => deleteImage(e)}
              >
                {t("global.remove_image")}
              </button>
              <button
                className="btn btn-primary"
                onClick={(e) => submitHandler(e)}
              >
                {t("global.save")}
              </button>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};
