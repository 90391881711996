import React, { useEffect, useState, useCallback } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useParams } from "react-router-dom";
import { AdminGalleryService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import ImageViewer from "react-simple-image-viewer";

export interface IPhoto {
  src: string;
  width: number;
  height: number;
}

export const Gallery = ({
  setIsMenuOpened,

  fileHandler,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currentTheme = useSelector((state: RootState) => state.theme);
  const [albums, setAlbums] = useState<any[]>([]);
  const [state, setState] = useState<any[]>();
  const [newIds, setNewIds] = useState<any[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index: any) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    updatePosition(arrayMoveImmutable(state as any, oldIndex, newIndex));
    setState((array: any) => arrayMoveImmutable(array, oldIndex, newIndex));
  };
  const updatePosition = (newIds: number[]) => {
    const newArray = newIds?.map((item: any) => item?.id);
    AdminGalleryService.updatePosition({ ids: newArray });
  };
  useEffect(() => {
    if (!!Number(id)) {
      AdminGalleryService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          const mapped = data?.data?.map((doc: any) => {
            return {
              id: doc.image?.id,
              path: doc.image.path,
            };
          });

          setState(mapped);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [id]);
  return (
    <Card>
      <div>
        <SortableList
          onSortEnd={onSortEnd}
          className="list"
          draggedItemClassName="dragged"
        >
          {state?.map((item, index) => (
            <SortableItem key={item?.id}>
              <img
                src={item?.path}
                className="object-fit-cover cursor-move"
                onDoubleClick={() => openImageViewer(item?.id)}
                key={item?.id}
                width={250}
                height={250}
                style={{ margin: "2px" }}
                alt=""
              />
              {/* <div className="item">{item}</div> */}
            </SortableItem>
          ))}
        </SortableList>
        {isViewerOpen && (
          <ImageViewer
            src={state!}
            backgroundStyle={{ zIndex: 10000 }}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </Card>
  );
};
